@import 'styles/_colors';
.society-profiles {
  margin-top: 30px;
}

@media screen and (min-width: 462px) {
  .card {
    font-size: calc(14px);
    line-height: calc(1.1em + 0.5vw);
  }
}

.card-img {
  max-height: 100% !important;
}

@media screen and (max-width: 461px) {
  .card {
    font-size: calc(9px + 0.25vw);
    line-height: calc(1.1em + 0.5vw);
  }
}

.card {
  padding-left: 15px;
  border-width: 0px;
  width: 100%;
}

.card-header {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $color-eng;
}

.card-full-width {
  width: 100%;
}

.card-text {
  color: white;
  text-align: left;
}
