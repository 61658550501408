.social-links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  a {
    color: white !important;
  }

  .fab, .fas {
    font-size: 40px;
  }

  img {
    max-height: 40px;
    width: auto;
  }
}

.social-links-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
}