@import 'styles/_colors';
.cal {
  height: 600px;
}

.cal-add-btn {
  float: right;
  background-color: $color-eng;
  border-width: 0px;
  margin-bottom: 3px;
}

.cal-add-btn:hover {
  background-color: $color-eng-light;
}

@media (max-width: 992px) {
}
