@import 'styles/_colors';

.action-grid {
  display: grid;
  grid-template-columns: auto min-content;
  grid-gap: 20px 10px;
  align-items: center;
}

.no-bottom-space {
  margin-bottom: 0;
}

.way-btn {
  float: right;
  background-color: $color-math;
  border-width: 0px;
}

.way-btn:hover {
  background-color: $color-math-light;
}
